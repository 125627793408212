<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo SP IMEI đã bán'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="2">
            <date-picker
              placeholder="Từ ngày"
              class="form-control-sm"
              :config="dpConfigs.start"
              v-model="dpForm.startDate"
            ></date-picker>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Đến ngày"
              class="form-control-sm"
              :config="dpConfigs.end"
              v-model="dpForm.endDate"
            ></date-picker>
          </b-col>
          <b-col md="5" class="pr-0">
            <multiselect
              v-model="providerValues"
              :options="optionProviders"
              :multiple="true"
              track-by="id"
              :close-on-select="false"
              @select="onSelected($event, 'Providers', true)"
              @remove="onSelected($event, 'Providers', false)"
              :show-labels="false"
              :showNoResults="false"
              :showPointer="false"
              placeholder="Chọn NCC"
              :custom-label="(option) => option.name"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} NCC đã chọn</span
                ></template
              >
              <span class="checkbox-label" slot="option" slot-scope="scope">
                {{ scope.option.name }}
                <input
                  class="checkbox-style"
                  type="checkbox"
                  v-model="scope.option.checked"
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col md="3">
            <b-button variant="primary" size="sm" @click="reportClick()"
              >Xuất báo cáo</b-button
            >
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { BASE_URL } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
// import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import axios from 'axios';
import fileDownload from '../../../utils/file-download';
import Multiselect from 'vue-multiselect';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      searchFromDay: '',
      searchToDay: '',
      providerValues: [],
      optionProviders: [],
    };
  },
  methods: {
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    reportClick: async function () {
      const listProviderId = this.getValuesMultiSelect(
        this.optionProviders,
        'id'
      );
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        this.makeToastFaile('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        this.makeToastFaile('Bạn chưa chọn ngày kết thúc!');
        return;
      }

      const params = {
        fromDate: searchFromDay,
        toDate: searchToDay,
        providerIds: listProviderId,
      };

      const url = `${BASE_URL}product-imei-sale/excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.optionProviders = data.data.providers.map((item) => {
          return {
            id: item.id,
            name: item.name,
            checked: false,
          };
        });
      });
    },
    onSelected(option, type, value) {
      switch (type) {
        case 'Providers':
          this.checkedOptions(this.optionProviders, option, 'id', value);
          break;
        default:
          break;
      }
    },
    checkedOptions(items, option, prop, value) {
      const index = items.findIndex((item) => item[prop] === option[prop]);
      if (index > -1) {
        items[index].checked = value;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo SP IMEI đã bán', route: '/product-imei-sale-reports' },
      { title: 'Danh sách SP IMEI đã bán' },
    ]);
  },
  created() {
    this.fetchProvider();
  },
  components: {
    KTCodePreview,
    datePicker,
    Multiselect,
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.productCode:hover {
  text-decoration: underline;
}

.checkbox-style {
  position: absolute;
  right: 0.7vw;
}

.multiselect__tags {
  height: calc(1.35em + 1.1rem + 2px);
  display: block;
  border-radius: 0.28rem;
  border: 1px solid #ced4da;
  font-size: 0.925rem;
  min-height: initial;
  padding-top: 0.3rem;
}

.multiselect__select {
  width: 3rem;
  height: 2.5rem;
  top: 0.1rem;
}

.multiselect__placeholder {
  color: #495057;
  padding-left: 0.2rem;
}

.multiselect__element {
  font-size: 0.925rem;
}

.multiselect__input {
  padding-top: 0.2rem;
  font-size: 0.925rem;
}

.multiselect__single {
  font-size: 0.925rem;
  line-height: 1.9;
  padding-left: 0.2rem;
  display: inline-block;
  max-width: 100%;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
